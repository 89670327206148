import { Link, useLocation } from "react-router-dom";
import HeaderBar from "../components/headerBar";
import { useEffect, useMemo } from "react";

import { login } from "../api";

let hasLogin: boolean = false;

function App() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let code: string = query.get("code") || "";
  useEffect(() => {
    if (!code && !window.sessionStorage.getItem("token")) {
      var url_code =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        // "wx4fea1b1463cedad2" +
        "wx923a5188a010d120" +
        "&redirect_uri=" +
        // encodeURIComponent("http://192.168.67.47:3000") +
        encodeURIComponent("https://qrai.simpletool.cn") +
        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";

      window.location.href = url_code;
    }
  }, []);
  const verifyIsLogin = async () => {
    if (!hasLogin) {
      login({ code, appId: "wx923a5188a010d120" }).then((res) => {
        code = "";
        window.sessionStorage.setItem("token", res.data.token);
        window.sessionStorage.setItem(
          "userName",
          res.data.userInfo.nickname || ""
        );
      });
    }
  };
  useMemo(() => {
    if (code && !window.sessionStorage.getItem("token")) {
      verifyIsLogin();
      hasLogin = true;
    }
  }, [code]);

  return (
    <div className="relative bg-gray-900 min-h-screen overflow-hidden flex flex-col items-center opacity-0 animate-show">
      <HeaderBar />
      <div className="relative mt-20 items-center gap-52 max-w-md sm:flex-wrap sm:flex sm:max-w-full">
        <div className="m-5">
          <div
            className="absolute left-1/2 z-0 top-0 -translate-x-1/2 blur-[100px] xl:-top-6"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 animate-homeFloat"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="flex-col flex z-10 shrink-0">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              二维码与插画
            </h1>
            <p className="mt-4 text-xl text-gray-500 max-w-md">
              利用强大的 Ai 绘画模型，将二维码改造成任何你希望的样子！
            </p>
            <Link
              to="/draw"
              className="z-10 cursor-pointer duration-300 rounded-md mt-10 bg-[#9089fc10] border-[1px] border-[#9089fc30] text-[#9089fc] w-fit text-xl px-5 py-2 hover:scale-95 md:rounded-2xl md:py-5 md:px-10 md:text-3xl"
            >
              立即体验
            </Link>
          </div>
        </div>

        <img
          src={require("../../src/resources/img/logo.png")}
          alt=""
          className=" bg-[#ffffff80] border-[10px] border-[#cccccc50] hover:bg-white object-cover z-10 mx-auto rounded-3xl my-10 w-[500px] opacity-80 hover:opacity-100 duration-500"
        />
      </div>
    </div>
  );
}

export default App;
