import React, { useEffect, useState } from "react";
import HeaderBar from "../../components/headerBar";

import "./index.css";
import { createOrder, getGoodsList, getUserRights } from "../../api";
import payUtil from "../../utils/pay";

const GoodsCard = (props: { detail: any; onClick: any; selectId: number }) => {
  const { detail = {}, onClick, selectId } = props;

  return (
    <div
      className={`goodsCard px-5 py-3 mr-2 ${
        selectId === detail.id ? "selected" : ""
      }`}
      onClick={onClick}
    >
      <div className="goods-count">{detail.displayConfiguration.detail}</div>
      <div className="goods-price">
        ¥{((detail.prize / 1000) * 10).toFixed(2)}
      </div>
      <div className="goods-origin">
        {detail.displayConfiguration.originalPrice}
      </div>
    </div>
  );
};

const Mine = () => {
  const [goods, setGoods] = useState([]);
  const [userRight, setUserRight] = useState<any>({});
  const [selectGood, setSelectGood] = useState<any>({});

  useEffect(() => {
    if (goods.length === 0) {
      getGoodsList({ goodsType: "AI_QRCODE_GENERATE_TIMES" }).then((res) => {
        setGoods(res.data);
        setSelectGood(res.data[0]);
      });
    }
  }, [goods]);

  useEffect(() => {
    getUserRights().then((res) => {
      setUserRight(res.data);
    });
  }, []);

  const handleClickGoods = (item: any) => {
    setSelectGood(item);
  };

  const handleBuy = () => {
    createOrder({ appId: "wx923a5188a010d120", goodsId: selectGood.id }).then(
      (res) => {
        console.log(res);
        payUtil.toPay({ ...res.data, package: res.data.packageVal });
      }
    );
  };

  return (
    <div className="px-2 relative bg-gray-900 min-h-screen overflow-hidden flex flex-col items-center opacity-0 animate-show">
      <HeaderBar />
      <div className="flex p-2 items-center my-4 user-content">
        <img
          src="https://simpletool-1254123789.cos.ap-chengdu.myqcloud.com/houseMap/upload/1/1698301711203WeChat1b1f7447f7970ee8e7299a3777f8b9f0.png"
          alt=""
          className="mr-2 avatar"
        />
        <div>
          <div className="username">
            {window.sessionStorage.getItem("userName") || ""}
          </div>
          <div className="userdesc">普通会员</div>
        </div>
        <div className="flex-1 text-right surplus">
          剩余次数：{userRight?.aiQrcodeGenerateTime || 0}
        </div>
      </div>

      <div className="goods-list mb-6">
        {goods.map((item: any) => {
          return (
            <GoodsCard
              onClick={() => {
                handleClickGoods(item);
              }}
              detail={item}
              selectId={selectGood.id || 0}
            />
          );
        })}
      </div>

      {selectGood.displayConfiguration?.detail && (
        <div className="goods-btn" onClick={handleBuy}>
          立即购买 / {selectGood.displayConfiguration.detail}
        </div>
      )}
    </div>
  );
};

export default Mine;
