import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HeaderBar from "../../components/headerBar";

export default function QandA() {
  const [qaList, setQalist] = useState([
    {
      q: "为什么我生成的图片边框背景是白色？",
      a: (
        <div className="">
          默认生成使用的二维码垫图边框就是白色的，ai绘画时候会默认把这片留白，可以尝试给二维码边缘空白地方添加一些杂色，试试这个
          <a
            href="https://qrcode.antfu.me/"
            target="blank"
            className=" text-[#9089fc] mx-2"
          >
            qrcode.antfu.me ⤴
          </a>
        </div>
      ),
    },
    {
      q: "如何去掉水印？",
      a: (
        <div>
          <span className=" line-through text-[#ffffff50]">
            这个问题，充钱就能解决。
          </span>
          天下没有免费的午餐，你免费获取了精美的二维码，我们获得宣传收益，
          <span className=" text-[rgb(91,198,151)] font-black">
            当然我们会在接下来推出会员服务，你可以生成更加高质量且无水印的二维码！
          </span>
          只需要一顿饭钱🍗～
        </div>
      ),
    },
    {
      q: "二维码扫不出来怎么办？",
      a: (
        <div>
          打开{" "}
          <span className=" text-[rgb(91,198,151)] font-black">高级模式</span>
          ，尝试{" "}
          <span className=" text-[rgb(91,198,151)] font-black">
            调高二维码权重
          </span>
        </div>
      ),
    },
  ]);

  function renderQaList() {
    return (
      <div className=" flex flex-col sm:flex-row flex-wrap w-full sm:p-8 items-start text-[#9089fc]">
        {qaList.map((qa, index) => {
          return (
            <div className=" bg-[#9089fc10] w-[100%_-_2rem] max-w-[400px] m-4 p-8 rounded-lg animate-show">
              <div className=" text-xl font-bold">{qa.q}</div>
              <div className=" text-lg text-[#ffffff]">{qa.a}</div>
            </div>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    if (!window.sessionStorage.getItem("token")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen w-screen flex flex-col items-center">
      <div
        className=" absolute top-0 right-0 m-4 text-[#ffffff40] cursor-pointer "
        onClick={() => {
          toast(`当前为测试版本，遇到问题可以联系wx:rabithua...`, {
            icon: "🚧",
          });
        }}
      >
        Beta
      </div>
      <HeaderBar />
      {renderQaList()}
    </div>
  );
}
