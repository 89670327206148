import axios from "axios";
import toast from "react-hot-toast";

const whiteList = ["/api/aigc/qrocode/generate"];

const request = axios.create({
  baseURL: "https://api.simpletool.cn", // url = base url + request url
  timeout: 5000, // request timeout
});

request.interceptors.request.use(
  (config) => {
    config.headers["token"] = sessionStorage.getItem("token") || "";
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0 && res.code !== 200) {
      if (response.config.url && whiteList.indexOf(response.config.url) > -1) {
        // 白名单中，可以不报错。直接return
        return Promise.reject(res);
      } else if (res.message === "未登录") {
        window.sessionStorage.removeItem("token");
        window.location.href = "/";
      } else {
        console.log("接口信息报错", res.message);
        toast.error(res.message);
      }
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
