export default function HeaderBar() {
  const handleClick = (path: string) => {
    if (!window.sessionStorage.getItem("token")) {
      return;
    }
    window.location.href = path;
  };
  return (
    <div className=" noScrollBar flex px-4 py-2  text-[#9089fc] bg-[#9089fc10] border-[1px] border-[#9089fc30] backdrop-blur-3xl sticky max-w-[80%] overflow-x-scroll sm:overflow-auto top-2 z-10 my-4 gap-2 rounded-lg">
      <a href="/" className=" shrink-0">
        首页
      </a>
      <span className=" text-[#ffffff40]"> ｜ </span>
      <a
        className=" shrink-0  hover:after:content-['⤴'] hover:after:absolute"
        onClick={() => {
          handleClick("/draw");
        }}
        href="javascript:void(0)"
      >
        绘画
      </a>
      <span className=" text-[#ffffff40]"> ｜ </span>
      <a
        className=" shrink-0 cursor-not-allowed  hover:after:content-['⤴'] hover:after:absolute"
        onClick={() => {
          handleClick("/mine");
        }}
        href="javascript:void(0)"
      >
        我的
      </a>
      <span className=" text-[#ffffff40]"> ｜ </span>
      <a
        className=" shrink-0 cursor-not-allowed  hover:after:content-['⤴'] hover:after:absolute"
        onClick={() => {
          handleClick("/history");
        }}
        href="javascript:void(0)"
      >
        历史记录
      </a>
      <span className=" text-[#ffffff40]"> ｜ </span>
      <a
        className=" shrink-0"
        onClick={() => {
          handleClick("/question");
        }}
        href="javascript:void(0)"
      >
        遇到问题
      </a>
      <span className=" text-[#ffffff40]"> ｜ </span>
      <a
        className=" shrink-0"
        onClick={() => {
          handleClick("/about");
        }}
        href="javascript:void(0)"
      >
        联系我们
      </a>
    </div>
  );
}
