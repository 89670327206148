import { useEffect, useState } from "react";
import drawBody from "../../resources/json/drawBody.json";
import templates from "../../resources/json/templates.json";
import toast from "react-hot-toast";
import { Html5Qrcode } from "html5-qrcode";
import { QRCodeCanvas } from "qrcode.react";
import HeaderBar from "../../components/headerBar";
import { getTemplateList, getUserRights, qrcodeGenerate } from "../../api";
import { Dialog } from "antd-mobile";

export default function Draw() {
  const [content, setContent] = useState("qrai.simpletool.cn");
  const [data, setdata] = useState(templates.list[0]);
  const [templateCode, setTemplateCode] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [qrCodeWeight, setQrCodeWeight] = useState(2.2);
  const moreOption = false;
  const [result, setResult]: [any, Function] = useState(null);
  const [progress, setProgress]: [any, Function] = useState({});
  const [createBtnDisable, setCreateBtnDisable] = useState(false);
  const [moreChange, setMoreChange] = useState(false);
  const [base64Qrcode, setBase64Qrcode] = useState("");
  const [scanContent, setScanContent] = useState("");
  const [useWaterMark, setUseWaterMark] = useState(true);
  const [drawMode, setDrawMode] = useState("template");

  useEffect(() => {
    checkProgress();
    setInterval(() => {
      checkProgress();
    }, 3000);

    if (!window.sessionStorage.getItem("token")) {
      window.location.href = "/";
    }
  }, []);

  function handleImageUpload(file: any) {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const img = new Image();
        img.onload = function () {
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;
          if (aspectRatio === 1) {
            console.log("The image has an aspect ratio of 1.");
          } else {
            // toast(
            //   (t) => (
            //     <div className=" flex gap-2">
            //       检测到图片非规范二维码，是否转化为普通二维码
            //       <button
            //         className=" px-4 py-2 bg-[#9089fc] text-white rounded-md shrink-0"
            //         onClick={() => {
            //           toast.dismiss(t.id);
            //           setBase64Qrcode("");
            //           setScanContent("");
            //         }}
            //       >
            //         转化
            //       </button>
            //     </div>
            //   ),
            //   {
            //     duration: 6000,
            //   }
            // );
            setBase64Qrcode("");
            setScanContent("");
            console.log("The image does not have an aspect ratio of 1.");
          }
        };
        img.src = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  function convertImageToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  function loadImage(src: any) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = src;
    });
  }

  async function waterMarkIt(imageUrl: any, resultData: any) {
    return new Promise((resolve, reject) => {
      try {
        if (useWaterMark) {
          const canvas: any = document.getElementById("wateraCanva"); // 获取canvas类型的二维码
          const ctx = canvas?.getContext("2d");

          const image = imageUrl;

          const waterMark = require("../../resources/img/waterMark.png");

          canvas.width = data.width;
          canvas.height = data.height;

          loadImage(imageUrl).then((w) => {
            ctx.drawImage(w, 0, 0);
            loadImage(waterMark).then((i) => {
              ctx.drawImage(i, data.width - 300, data.height - 100);
              let base64 = canvas.toDataURL("image/png");
              resultData = base64;
              setResult(resultData);
              resolve(image);
            });
          });
        } else {
          resultData = imageUrl;
          setResult(resultData);
          resolve(imageUrl);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  function checkProgress() {
    // var config = {
    //   method: "get",
    //   url: "https://api.simpletool.cn/api/qrcode/generate",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     setProgress(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  async function drawIt(): Promise<void> {
    setCreateBtnDisable(true);

    const permit = await getUserRights();

    let qrCodeBase64 = base64Qrcode ? base64Qrcode : base64It();
    let body = drawBody;
    let { prompt, negative_prompt } = data;
    body = {
      ...body,
      prompt,
      steps: data.steps ? data.steps : 30,
      width: data.width ? data.width : 30,
      height: data.height ? data.height : 30,
      negative_prompt,
      init_images: [qrCodeBase64] as any,
      controlnet_input_image: [qrCodeBase64] as any,
      denoising_strength: data.denoising_strength,
      alwayson_scripts: {
        ...body.alwayson_scripts,
        controlnet: {
          ...body.alwayson_scripts.controlnet,
          args: [
            {
              ...body.alwayson_scripts.controlnet.args[0],
              ...data.alwayson_scripts.controlnet.args[0],
            },
          ],
        },
      },
    };

    await qrcodeGenerate({
      qrcodeContent: content,
      templateCode: templateCode,
      customParam: {
        prompt,
        negative_prompt,
        qrCodeWeight: qrCodeWeight,
      },
    })
      .then(async function (response) {
        document.title = "生成完毕✨";
        Dialog.confirm({
          content: "二维码生成中，请到历史记录查看。",
          confirmText: "去查看",
          cancelText: "取消",
          onConfirm: () => {
            window.location.href = "/history";
          },
          onCancel: () => {},
        });
        setResult(response.data.data);
        setTimeout(() => {
          toast.custom(
            (t) => (
              <div className=" max-w-[80%] flex flex-col gap-4 m-4 px-4 py-2 bg-white rounded-md animate-show">
                🤖: 对生成效果满意吗？
                <div className=" flex gap-1 text-sm cursor-pointer justify-around">
                  <div
                    className=" border-[#9089fc] border-[1px] px-2 py-1 rounded-md text-[#9089fc] duration-300 hover:scale-90"
                    data-umami-event="反馈：不满意"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    不满意
                  </div>
                  <div
                    className=" bg-[#9089fc] px-2 py-1 rounded-md text-white duration-300 hover:scale-90"
                    data-umami-event="反馈：还可以"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    还可以
                  </div>
                  <div
                    className=" bg-[#9089fc] px-2 py-1 rounded-md text-white duration-300 hover:scale-90"
                    data-umami-event="反馈：泰裤辣"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    泰裤辣
                  </div>
                </div>
              </div>
            ),
            {
              duration: 8000,
              position: "bottom-right",
            }
          );
        }, 1000);
        setCreateBtnDisable(false);
        return Promise.resolve();
      })
      .catch((err) => {
        setCreateBtnDisable(false);
        if (err.code === -2) {
          // 次数用完
          Dialog.alert({
            content: err.message || "生成次数已用完，请充值",
            confirmText: "去充值",
            onConfirm: () => {
              window.location.href = "/mine";
            },
          });
        }
      });
  }

  function renderResult() {
    return (
      <div className=" fixed top-0 left-0 mt-5 z-10 min-w-full min-h-screen flex justify-center items-center">
        <div className=" animate-show p-5 no-sbar relative bg-[#9089fc30] border-[2px] border-[#9089fc40] rounded-3xl overflow-y-scroll overflow-x-hidden flex flex-col gap-10 w-[80%] max-w-[600px] h-[80%] backdrop-blur-3xl">
          <div
            onClick={() => {
              setCreateBtnDisable(false);
              setResult(null);
              document.title = "AI二维码";
            }}
            className=" absolute right-3 top-4 bg-[#ffffff10] hover:bg-red-500 text-white font-black text-center font-mono w-10 h-6 rounded-2xl duration-300 hover:scale-125 cursor-pointer"
          >
            <div className=" rotate-45 translate-y-[-2px] text-lg">+</div>
          </div>
          <div className=" text-white m-5 text-center">
            右键或者长按保存，无法扫描可调整二维码权重
            <span
              className=" font-semibold text-[#9089fc] underline cursor-pointer "
              onClick={() => {
                setResult(null);
                drawIt();
              }}
            >
              重新生成
            </span>
            。
          </div>
          <div className="flex pb-5 flex-col gap-10 justify-center items-center sm:flex-row">
            <img
              src={"data:image/png;base64," + result}
              className=" w-[400px] object-contain max-w-[80%] rounded-lg"
              alt=""
            />

            {/* <img
              src={"data:image/png;base64," + result.images[1]}
              className=" w-[400px] max-w-[80%] rounded-lg"
              alt=""
            /> */}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    getTemplateList().then((res) => {
      setTemplateList(res.data || []);
      setTemplateCode(res.data[0].templateCode);
    });
  }, []);

  function renderTemplates() {
    return (
      <div className=" flex gap-5 sticky flex-col animate-show">
        <label className="block z-30 text-xl font-medium text-white ">
          模板 · <span className=" opacity-10 text-base">template</span>
        </label>

        <div className="flex gap-2 flex-wrap aspect-square overflow-y-scroll overflow-x-hidden">
          {templateList.length === 0 ? (
            <div>loading...</div>
          ) : (
            templateList.map((temp: any, index: number) => (
              <div
                className="group w-[31.5%] my-1 aspect-square duration-300 relative hover:scale-[1.05] hover:z-10"
                key={`templates_${index}`}
                onClick={() => {
                  toast(`当前预设：${temp.templateName}`, {
                    icon: "🤖",
                  });
                  setdata(templates.list[index]);
                  setTemplateCode(temp.templateCode);
                }}
              >
                <img
                  data-umami-event={"预设：" + temp.templateName}
                  className=" w-full h-full bg-slate-400"
                  src={temp.templatePreview}
                  alt={temp.templateName}
                />
                {temp.templateName ? (
                  <div className=" group-hover:opacity-0 duration-300 absolute bottom-0 w-full bg-[#00000080] text-white text-sm py-1 text-center">
                    {temp.templateName}
                  </div>
                ) : null}
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function base64It() {
    const canvas = document.getElementById("qrCode"); // 获取canvas类型的二维码
    // @ts-ignore
    let src = canvas?.toDataURL(); // 将canvas对象转换为图片的data url
    return src;
  }

  function renderQrcode() {
    return (
      <>
        <div className=" mb-4 max-w-[600px] w-[80%] flex flex-col gap-8">
          <div>
            <label className="flex items-center mb-5 text-xl gap-5 font-medium text-white ">
              二维码信息
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={moreChange}
                  onChange={() => {
                    setMoreChange(!moreChange);
                  }}
                />
                <div className="w-11 h-6 bg-[#9089fc30] rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#9089fc]"></div>
                <span className="ml-3 text-sm font-medium text-white">
                  高级模式
                </span>
              </label>
            </label>
            {base64Qrcode ? null : (
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-white bg-[#9089fc20] border border-r-0 border-[#9089fc10] rounded-l-md ">
                  http://
                </span>
                <input
                  type="text"
                  value={content}
                  id="website-admin"
                  className="rounded-none rounded-r-lg bg-[#9089fc10] border text-white focus:ring-[#9089fc50] focus:border-[#9089fc50] block flex-1 min-w-0 w-full text-sm border-[#9089fc30] p-2.5  "
                  placeholder="qrai.simpletool.cn"
                  onInput={(e: any) => {
                    setContent(e.target.value);
                  }}
                />
              </div>
            )}
            {base64Qrcode ? (
              <>
                <div className=" py-2 rounded-md text-white ">
                  扫描结果：{scanContent}
                </div>
                <div
                  className=" px-4 py-2 rounded-md text-center text-[#F33636]  cursor-pointer bg-[#F3363640] hover:bg-[#F3363620]"
                  onClick={() => {
                    setBase64Qrcode("");
                    setScanContent("");
                  }}
                >
                  删除当前导入的二维码
                </div>
              </>
            ) : (
              <>
                {renderImgChoose()}
                {moreChange ? (
                  <p className="text-xs my-2 text-white ">
                    不同风格的二维码垫图会生成不同的结果，推荐使用
                    <a
                      href="https://qrcode.antfu.me/"
                      target="blank"
                      className=" text-[#9089fc] mx-2"
                    >
                      qrcode.antfu.me ⤴
                    </a>
                    来生成二维码～
                  </p>
                ) : null}
              </>
            )}
          </div>
          {content ? (
            <>
              {moreChange ? (
                <>
                  {moreOption ? (
                    <div className=" animate-show">
                      <label className="block mb-5 text-xl font-medium text-white">
                        插画混合强度 ·{" "}
                        <span className=" opacity-10 text-base">
                          Denoising strength · {data.denoising_strength}
                        </span>
                      </label>
                      <input
                        type="range"
                        min="0.5"
                        max="0.9"
                        step="0.01"
                        value={data.denoising_strength}
                        onInput={(e: any) => {
                          setdata({
                            ...data,
                            denoising_strength: parseFloat(e.target.value),
                          });
                        }}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                      />
                    </div>
                  ) : null}

                  <div className=" animate-show">
                    <label className="block mb-5 text-xl font-medium text-white">
                      二维码权重 ·{" "}
                      <span className=" opacity-10 text-base">
                        Qrcode Weight · {qrCodeWeight}
                      </span>
                    </label>
                    <input
                      type="range"
                      min="0.5"
                      max="4"
                      step="0.01"
                      value={qrCodeWeight}
                      onInput={(e: any) => {
                        setQrCodeWeight(parseFloat(e.target.value));
                      }}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                    <div className=" w-full mt-2 flex text-sm text-[#ffffff80]">
                      <div>(更加美观)</div>
                      <div className=" ml-auto">(更易扫描)</div>
                    </div>
                  </div>
                  {moreOption ? (
                    <>
                      <div className=" animate-show">
                        <label className="block mb-5 text-xl font-medium text-white">
                          二维码开始混合点 ·{" "}
                          <span className=" opacity-10 text-base">
                            Starting Control Step ·{" "}
                            {
                              data.alwayson_scripts.controlnet.args[0]
                                .guidance_start
                            }
                          </span>
                        </label>
                        <input
                          type="range"
                          min="0"
                          max="0.4"
                          step="0.01"
                          value={
                            data.alwayson_scripts.controlnet.args[0]
                              .guidance_start
                          }
                          onInput={(e: any) => {
                            setdata({
                              ...data,
                              alwayson_scripts: {
                                ...data.alwayson_scripts,
                                controlnet: {
                                  ...data.alwayson_scripts.controlnet,
                                  args: [
                                    {
                                      ...data.alwayson_scripts.controlnet
                                        .args[0],
                                      guidance_start: parseFloat(
                                        e.target.value
                                      ),
                                    },
                                  ],
                                },
                              },
                            });
                          }}
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                      </div>
                      <div className=" animate-show">
                        <label className="block mb-5 text-xl font-medium text-white">
                          二维码结束混合点 ·{" "}
                          <span className=" opacity-10 text-base">
                            Ending Control Step ·{" "}
                            {
                              data.alwayson_scripts.controlnet.args[0]
                                .guidance_end
                            }
                          </span>
                        </label>
                        <input
                          type="range"
                          min="0.6"
                          max="0.95"
                          step="0.01"
                          value={
                            data.alwayson_scripts.controlnet.args[0]
                              .guidance_end
                          }
                          onInput={(e: any) => {
                            setdata({
                              ...data,
                              alwayson_scripts: {
                                ...data.alwayson_scripts,
                                controlnet: {
                                  ...data.alwayson_scripts.controlnet,
                                  args: [
                                    {
                                      ...data.alwayson_scripts.controlnet
                                        .args[0],
                                      guidance_end: parseFloat(e.target.value),
                                    },
                                  ],
                                },
                              },
                            });
                          }}
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>
        {content ? (
          <div className=" animate-show opacity-0 flex flex-col gap-5 max-w-[600px] w-[80%]">
            {/* {base64Qrcode ? (
              <img
                className=" rounded-lg max-w-[500px]"
                src={base64Qrcode}
                alt=""
              />
            ) : ( */}
            <QRCodeCanvas
              level="H"
              size={300}
              includeMargin
              className=" bg-white rounded-lg max-w-[500px] hidden"
              value={`http://${content}`}
              id="qrCode"
            />
            {/* )} */}
            <div className="tabs tabs-boxed w-fit bg-[#4c487d90] backdrop-blur-3xl mb-4 cursor-pointer">
              <div
                className={
                  drawMode === "template"
                    ? "tab font-black tab-active duration-300"
                    : "tab text-white duration-300"
                }
                onClick={() => {
                  setDrawMode("template");
                }}
              >
                模板
              </div>
              <div
                className={
                  drawMode === "template"
                    ? "tab text-white duration-300"
                    : "tab font-black tab-active duration-300"
                }
                onClick={() => {
                  setDrawMode("prompt");
                }}
              >
                提示词
              </div>
            </div>
            {drawMode === "template" ? (
              renderTemplates()
            ) : (
              <>
                <div className=" animate-show">
                  <label className="block mb-5 text-xl font-medium text-white">
                    描述词 ·{" "}
                    <span className=" opacity-10 text-base">prompt</span>
                  </label>
                  <textarea
                    rows={4}
                    className="block p-2.5 w-full text-sm text-white bg-[#9089fc10] rounded-lg border border-[#9089fc30] focus:ring-[#9089fc50] focus:border-[#9089fc50] "
                    placeholder="(masterpiece, best quality:1.3), extremely high detailed, intricate"
                    value={data.prompt}
                    onInput={(e: any) => {
                      setdata({
                        ...data,
                        prompt: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className=" animate-show">
                  <label className="block mb-5 text-xl font-medium text-white">
                    反向描述词 ·{" "}
                    <span className=" opacity-10 text-base">
                      negative_prompt
                    </span>
                    <span
                      onClick={() => {
                        setdata({
                          ...data,
                          negative_prompt:
                            "easynegative, ng_deepnegative_v1_75t",
                        });
                      }}
                      className=" cursor-pointer mx-3 rounded-md px-3 py-1 text-sm bg-[#9089fc50] whitespace-nowrap text-[#9089fc] duration-300 hover:bg-[#9089fc20]"
                    >
                      使用默认值
                    </span>
                  </label>
                  <textarea
                    rows={4}
                    className="block p-2.5 w-full text-sm text-white bg-[#9089fc10] rounded-lg border border-[#9089fc30] focus:ring-[#9089fc50] focus:border-[#9089fc50] "
                    placeholder="可留空"
                    value={data.negative_prompt}
                    onInput={(e: any) => {
                      setdata({
                        ...data,
                        negative_prompt: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </>
            )}
            <button
              disabled={createBtnDisable ? true : data.prompt ? false : true}
              className={
                data.prompt
                  ? "cursor-pointer duration-300 w-full text-center rounded-md bg-[#9089fc] text-white font-bold text-xl px-6 py-3 hover:scale-95 flex flex-wrap gap-2 items-center justify-center"
                  : "duration-300 w-full text-center rounded-md bg-[#9089fc]  text-white font-bold text-xl px-6 py-3 hover:scale-95 opacity-20 cursor-not-allowed flex flex-wrap gap-2 items-center justify-center"
              }
              data-umami-event="绘制插画"
              onClick={() => {
                drawIt();
              }}
            >
              {createBtnDisable ? "生成中..." : "生成插画二维码"}
              {progress.state ? (
                <div className=" text-white text-sm text-center flex justify-center items-center">
                  {progress.state.job_count !== 0 ? (
                    <div className=" flex shrink-0 gap-2 items-center px-4 py-2 rounded-full text-yellow-500 bg-[#00000030] border-[1px] border-[#ffffff10]">
                      忙碌中
                      <div className=" w-1 h-1 rounded-full bg-yellow-500 shadow-lg shadow-yellow-500"></div>
                    </div>
                  ) : (
                    <div className=" flex shrink-0 gap-2 items-center px-4 py-2 rounded-full text-green-500 bg-[#00000030] border-[1px] border-[#ffffff10]">
                      空闲
                      <div className=" w-1 h-1 rounded-full bg-green-500 shadow-lg shadow-green-500"></div>
                    </div>
                  )}
                  {progress.state.job_count !== 0 ? (
                    <span className=" ml-5 shrink-0">
                      进度：
                      {(
                        (progress.state.sampling_step /
                          progress.state.sampling_steps) *
                        100
                      ).toFixed(0) + "%"}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </button>
            <p className="text-xs mb-2 text-center text-white ">
              建议在空闲时候生成插画二维码，避免链接请求时间过长出现断开的情况🙅🏻。
            </p>
          </div>
        ) : null}
      </>
    );
  }

  function renderImgChoose() {
    return (
      <div className="flex mt-4 items-center justify-center">
        <label
          htmlFor="dropzone-file"
          className="aspect-square flex flex-col items-center justify-center w-full h-64 border-2 border-[#9089fc60] border-dashed duration-300 rounded-lg cursor-pointer bg-[#9089fc10] hover:bg-[#9089fc30]"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="w-10 h-10 mb-3 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-white ">
              <span className="font-semibold">点击上传二维码</span>或者拖拽上传
            </p>
            <p className="text-xs text-white ">PNG \ JPG \ JPEG</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            accept="image/jpeg,image/jpg,image/png"
            className="hidden"
            onChange={(e) => {
              if (e.target.files && e.target.files?.length > 0) {
                // Scan QR Code
                const html5QrCode = new Html5Qrcode("reader");
                html5QrCode
                  .scanFile(e.target.files[0], true)
                  .then((decodedText) => {
                    toast.success("解析成功～");
                    // @ts-ignore
                    convertImageToBase64(e.target.files[0]).then((r) => {
                      setBase64Qrcode(r);
                    });
                    setScanContent(decodedText);
                    setContent(decodedText.replace(/^(https?:)?\/\//, ""));
                    // @ts-ignore
                    handleImageUpload(e.target.files[0]);
                  })
                  .catch((err) => {
                    // failure, handle it.
                    toast.error("请上传合法二维码图片");
                    console.log(`Error scanning file. Reason: ${err}`);
                  });
              }
            }}
          />
        </label>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 flex flex-col items-center">
      <div
        className=" absolute top-0 right-0 m-4 text-[#ffffff40] cursor-pointer "
        onClick={() => {
          toast(`当前为测试版本，遇到问题可以联系wx:rabithua...`, {
            icon: "🚧",
          });
        }}
      >
        Beta
      </div>
      <HeaderBar />
      <div className="relative  sm:p-10 min-h-screen py-10 w-full overflow-hidden gap-10 flex sm:flex-row flex-col justify-center items-center sm:items-baseline pb-16">
        {renderQrcode()}
        {result ? renderResult() : null}
        <div className=" absolute bottom-2 text-white text-sm">
          更多有趣玩法开发中，记得收藏本站💫
        </div>
        <canvas className="hidden" id="wateraCanva" />
        <div id="reader" className="hidden w-[500px]"></div>
      </div>
    </div>
  );
}
