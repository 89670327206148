import request from "./request";

export const login = async (params: { appId: string; code: string }) => {
  return await request({
    url: "/api/login/wechat-mp",
    method: "get",
    params: params,
  });
};

// 获取商品列表
export const getGoodsList = async (params: { goodsType: string }) => {
  return await request({
    url: "/api/goods/list",
    method: "get",
    params: params,
  });
};

// 创建订单
export const createOrder = async (data: { appId: string; goodsId: number }) => {
  return await request({
    url: "/api/order/create",
    method: "post",
    data,
  });
};

// 获取模版列表
export const getTemplateList = async (params?: {}) => {
  return await request({
    url: "/api/attribute/AI_QRCODE_TEMPLATE_LIST",
    method: "get",
    params: params,
  });
};

// 生成二维码
export const qrcodeGenerate = async (data: {
  qrcodeContent: string;
  templateCode: string;
  customParam: any;
  [x: string]: any;
}) => {
  return await request({
    url: "/api/aigc/qrocode/generate",
    method: "post",
    data,
  });
};

// 生成记录
export const recordDetail = async (params: { appId: string; code: string }) => {
  return await request({
    url: "/api/aigc/record/detail",
    method: "get",
    params: params,
  });
};

// 生成记录
export const getRecordList = async (data?: { partitionId?: number }) => {
  return await request({
    url: "/api/aigc/record/list",
    method: "post",
    data: data,
  });
};

// 获取用户剩余次数
export const getUserRights = async () => {
  return await request({
    url: "/api/user-rights/get",
    method: "get",
  });
};
